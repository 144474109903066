<template>
  <section class="junctions" v-if="allSystems.length">
    <div class="container container__title">
      <div class="junctions__title major-title">
        {{ $t('message.junctions.title') }}
      </div>
      <app-input
        v-model.trim="fastSearch"
        @input="showFilteredProducts()"
        :type="'search'"
        :isError="false"
        :field="{ name: 'search-junction' }"
        :icon="true"
      >
      </app-input>
    </div>
    <Junctions-nav :junctions="sectorFromState.junctions" :isShowDelete="true" :showList="true"/>
    <Products-filter
      :products="filteredJunctionsBySystem"
      :normalizedCategories="normalizedCategories"
      :categories="junctionsCategories"
      @returnFilteredProducts="showFilteredProducts"
    />
    <div class="container">
      <Products-view
        :products="productsToProductView"
        placement="junction"
        @productSelected="addJunctionToSector"
        @showGroupModal="showGroupModal"
        @createCustom="createCustomJunction"
      >
        <template #image>
          <img src="@/assets/img/icons/junction--create.svg" alt="create" />
        </template>
        <i18n path="message.junctions.create" class="create-btn__text">
          <template #break>
            <br />
          </template>
        </i18n>
      </Products-view>
      <Group-modal :isModalOpen="isModalOpen" :isShowClose="true" @close="close">
        <template #body>
          <Junctions-group :group="group" @itemSelected="itemSelected" />
        </template>
      </Group-modal>
      <div class="step-buttons">
        <button @click="goToPrevStep" class="btn btn-back">
          <img src="@/assets/img/icons/step--back.svg" alt="←" />
          <span class="btn__text">{{ getPrevStepText() }}</span>
        </button>
      </div>
    </div>
    <loading
      :active.sync="isLoading"
      :can-cancel="false"
      color="#E30713"
      :height="145"
      :width="145"
      :opacity="0.7"
      backgroundColor="#eeeeee"
      :z-index="3"
      :is-full-page="true"
    ></loading>
  </section>
</template>

<script>
import GroupModal from '@/components/elements/Modals/Modal'
import ProductsView from '@/components/smart/products/Products-view'
import ProductsFilter from '@/components/smart/products/Products-filter'
import JunctionsNav from '@/components/elements/Junction/JunctionsNav'
import JunctionsGroup from '@/components/smart/Junction/JunctionsGroup'
import AppInput from '@/components/dump/AppInput'
import { mapMutations, mapState, mapActions, mapGetters } from 'vuex'
import { isSomeWedgeIsEnabled } from '@/utils/customFunctions'
import { stepNumbers } from '@/utils/stepNumbers'

export default {
  data() {
    return {
      filteredProducts: [],
      isModalOpen: false,
      isLoading: false,
      isFiltered: false,
      fastSearch: '',
      group: {}
    }
  },
  components: {
    ProductsView,
    ProductsFilter,
    JunctionsNav,
    GroupModal,
    JunctionsGroup,
    AppInput
  },
  methods: {
    ...mapActions({
      updateStep: 'updateStep',
      getItem: 'getItem'
    }),
    ...mapMutations({
      ADD_JUNCTION_TO_SECTOR: 'ADD_JUNCTION_TO_SECTOR',
      CREATE_CUSTOM_JUNCTION: 'CREATE_CUSTOM_JUNCTION'
    }),
    addJunctionToSector(id, variables) {
      this.isLoading = true
      const isReplaceJunction =
        !!this.$route.params.isReplace && !!this.sectorFromState.junctions.length
      isReplaceJunction
        ? this.replaceJunction(id, null, variables)
        : this.addJunction(id, null, variables)
    },
    replaceJunction(id, groupId, variables = null) {
      const { replacementJunction } = this.$route.params
      const isReplacementJunctionExist = this.sectorJunctions.some((j) => j.uid === replacementJunction)
      const { sectorIndex } = this

      if (isReplacementJunctionExist) {
        const newJunctionIndex = this.sectorJunctions.findIndex(j => j.uid === replacementJunction)
        this.getItem({ type: 'junction', sectorIndex, junctionIndex: id, variables }).then((response) => {
          this.replaceDefaultJunctionMaterials(response.data.layers)
          this.ADD_JUNCTION_TO_SECTOR({
            sectorIndex,
            junctionId: id,
            junctionType: this.getJunctionType(id, groupId),
            layers: response.data.layers,
            groupId,
            replacementJunctionUid: replacementJunction,
            variables
          })
          this.isLoading = false
          this.$router.push(
            `/${this.$i18n.locale}/system/${this.sectorIndex}/junction/${newJunctionIndex}`
          )
        })
      } else {
        this.addJunction(id, groupId, variables)
      }
    },
    replaceDefaultJunctionMaterials(junctionLayers) {
      const flexSystemLayers = this.sectorFromState.layers.filter((layer) => layer.items.some((item) => item.hasAlternate && item.junctionProductReplace))

      if (flexSystemLayers) {
        flexSystemLayers.forEach((layer) => {
          const sameLayerInJunction = junctionLayers.find((junctionLayer) => junctionLayer.name === layer.name)
          if (sameLayerInJunction) {
            layer.items.forEach((item) => {
              const sameSublayer = sameLayerInJunction.items.find(j => j.name === item.name)
              if (sameSublayer && sameSublayer.material?.id !== item.material?.id) {
                sameSublayer.material = item.material
                junctionLayers.forEach((junctionLayer) => {
                  (junctionLayer?.items || []).forEach((item) => {
                    if (item.name === sameSublayer.name && item.hasAlternate) {
                      item.material = sameSublayer.material
                    }
                  })
                })
              }
            })
          }
        })
      }
    },
    addJunction(id, groupId, variables = null) {
      const { sectorIndex } = this
      this.getItem({ type: 'junction', sectorIndex, junctionIndex: id, variables }).then((response) => {
        this.replaceDefaultJunctionMaterials(response.data.layers)
        this.ADD_JUNCTION_TO_SECTOR({
          sectorIndex,
          junctionId: id,
          junctionType: this.getJunctionType(id, groupId),
          layers: response.data.layers,
          groupId,
          replacementJunctionUid: null,
          variables
        })
        this.isLoading = false
        this.$router.push(
          `/${this.$i18n.locale}/system/${this.sectorIndex}/junction/${this.junctionIndex}`
        )
      })
    },
    itemSelected(evt) {
      this.isModalOpen = false
      this.isLoading = true
      const isReplaceJunction =
        !!this.$route.params.isReplace && !!this.sectorFromState.junctions.length

      if (evt.variables) {
        this.addJunctionToSector(evt.groupId, evt.variables)
      } else {
        isReplaceJunction
          ? this.replaceJunction(evt.junctionId, evt.groupId)
          : this.addJunction(evt.junctionId, evt.groupId)
      }
    },
    getJunctionType(id, groupId) {
      return groupId
        ? this.allJunctions.find(p => p.id === groupId).items.find(p => p.id === id).type
        : this.allJunctions.find(p => p.id === id).type
    },
    createCustomJunction() {
      const isReplaceJunction =
        !!this.$route.params.isReplace && !!this.sectorFromState.junctions.length
      isReplaceJunction ? this.replaceExistJunctionToCustom() : this.addNewCustomJunction()
    },
    replaceExistJunctionToCustom() {
      const { replacementJunction } = this.$route.params
      const isReplacementJunctionExist = this.sectorJunctions.some(
        j => j.uid === replacementJunction
      )
      if (isReplacementJunctionExist) {
        const newJunctionIndex = this.sectorJunctions.findIndex(j => j.uid === replacementJunction)
        this.CREATE_CUSTOM_JUNCTION({
          sectorIndex: this.sectorIndex,
          replacementJunctionUid: replacementJunction
        })
        this.$router.push(
          `/${this.$i18n.locale}/system/${this.sectorIndex}/junction/${newJunctionIndex}`
        )
      } else {
        this.addNewCustomJunction()
      }
    },
    addNewCustomJunction() {
      this.CREATE_CUSTOM_JUNCTION({
        sectorIndex: this.sectorIndex,
        replacementJunctionUid: null
      })
      this.$router.push(
        `/${this.$i18n.locale}/system/${this.sectorIndex}/junction/${this.junctionIndex}`
      )
    },
    showFilteredProducts(products = this.isFiltered ? this.filteredProducts : this.filteredJunctionsBySystem) {
      this.isFiltered = true
      this.filteredProducts = products
    },
    showGroupModal(group) {
      this.group = group
      this.isModalOpen = true
    },
    close() {
      this.isModalOpen = false
    },
    isJunctionSingle(junction) {
      return Object.prototype.hasOwnProperty.call(junction, 'groupName')
    },
    goToPrevStep() {
      if (this.isSomeWedgeIsEnabled) {
        this.$router.push(`/${this.$i18n.locale}/wedge/${this.sectorIndex}`)
      } else {
        this.$router.push(`/${this.$i18n.locale}/system/${this.sectorIndex}`)
      }
    },
    getPrevStepText() {
      return this.isSomeWedgeIsEnabled
        ? `${this.$i18n.t('message.stepButtons.setUpWedge')}`
        : `${this.$i18n.t('message.stepButtons.setUpSystem')}`
    },
    insulationLayerForFilter(withoutInsulation) {
      const layer = this.sectorFromState.layers
        .filter(layer => {
          return (
            (withoutInsulation || layer.insulation) &&
            layer.items.some(
              item => (withoutInsulation || item.insulation) && item.material.isolationType !== ''
            )
          )
        })
        .pop()
      return layer?.items.filter(item => item.material.isolationType !== '').pop()
    },
    filterJunctions() {
      if (this.isFiltered) {
        if (this.fastSearch !== '') {
          return this.filteredProducts.filter(
            (junction) => junction.name
              ? junction.name.toLowerCase().includes(this.fastSearch?.toLowerCase())
              : junction.groupName.toLowerCase().includes(this.fastSearch?.toLowerCase())
          )
        }

        return this.filteredProducts
      }

      return this.filteredJunctionsBySystem
    },
    markUsedJunctions(junctions) {
      const currentSectorJunctions = this.$store.getters.getSector(this.sectorIndex).junctions

      if (currentSectorJunctions.length) {
        const usedJunctionsIds = currentSectorJunctions.map(junction => junction.id)

        return junctions.map(junction => {
          const isUsed = junction.name
            ? usedJunctionsIds.includes(junction.id)
            : junction.items.some(item => usedJunctionsIds.includes(item.id))

          return isUsed ? { ...junction, isUsed } : junction
        })
      }

      return junctions
    }
  },
  mounted() {
    if (this.allSystems.length === 0) {
      this.$router.push(`/${this.$i18n.locale}/`)
    }
    this.updateStep(stepNumbers.junctions)
  },
  computed: {
    ...mapState({
      junctionsCategories: state => state.junctionsCategories,
      responseJunctionLayers: state => state.junctionLayers
    }),
    ...mapGetters({
      allSystems: 'allPublishedSystems',
      allJunctions: 'allPublishedJunctions'
    }),
    normalizedCategories() {
      return Object.fromEntries(this.junctionsCategories.map(c => [c.id, []]))
    },
    sectorIndex() {
      return this.$route.params.id ? this.$route.params.id : 0
    },
    sectorFromState() {
      return this.$store.getters.getSector(this.sectorIndex)
    },
    sectorJunctions() {
      return this.sectorFromState.junctions
    },
    junctionIndex() {
      return this.sectorJunctions.length - 1
    },
    systemBaseType() {
      return this.sectorFromState.layers.find(layer => layer.isBaseLayer).items[0].baseTypeId
    },
    isFilterByInsulationNeed() {
      const insulationLayers = this.sectorFromState.layers.filter(
        layer => layer.insulation && layer.isEnabled
      )
      const validInsulationLayers = insulationLayers.filter(layer =>
        layer.items.some(item => item.insulation)
      )
      return validInsulationLayers.some(layer =>
        layer.items.some(item => item.material.isolationType !== '')
      )
    },
    filteredJunctionsBySystem() {
      return this.sectorFromState.isCustom
        ? this.filteredJunctionsCustomSystem
        : this.filteredJunctionsDefaultSystem
    },
    filteredJunctionsCustomSystem() {
      const insulationId = this.insulationLayerForFilter(!this.isFilterByInsulationNeed).material
        .isolationType
      return this.allJunctions.filter(junction => {
        return (
          junction.baseType.includes(this.systemBaseType) &&
          junction.isolationType.includes(insulationId)
        )
      })
    },
    filteredJunctionsDefaultSystem() {
      return this.allJunctions.filter(j => j.systems_ref.includes(this.sectorFromState.system))
    },
    productsToProductView() {
      const filteredJunctions = this.filterJunctions()

      return this.markUsedJunctions(filteredJunctions)
    },
    isSomeWedgeIsEnabled() {
      return isSomeWedgeIsEnabled(this.sectorFromState.layers)
    }
  }
}
</script>

<style scoped lang="sass">
.junctions
  &__filter
    height: rem(64)
    background: #fff
</style>
